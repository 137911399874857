import { Injectable } from '@angular/core'
import { CanActivate, Router } from '@angular/router'
import * as moment from 'moment'
import { url } from '../../../environments/environment'
import { HttpClient } from '@angular/common/http'
import { CurrentUserService } from '../../shared/services/currentUser.service'

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private http: HttpClient) {}

  async canActivate() {
    const now = moment().format('YYYY-MM-DD HH:mm:ss')
    if (sessionStorage.getItem('loggedin')) {
      sessionStorage.removeItem('loggedin')
      await this.sleep(1500)
      const service = new CurrentUserService()
      const user = await service.getCurrentUser()

      if (user?.permissions?.analytics?.view) {
        this.router.navigate(['/advertisers'])
      }
      return true
    }

    if (now <= localStorage.getItem('expiring_date')) {
      return true
    }

    localStorage.removeItem('expiring_date')
    localStorage.removeItem('me')
    await this.router.navigate(['/login'])
    return false
  }

  protected sleep = (milliseconds) =>
    new Promise((resolve) => {
      setTimeout(resolve, milliseconds)
    })
}
